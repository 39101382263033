import type { RouteRecordRaw } from 'vue-router';
import login from '@common/lib/modules/auth/routes/routes';
import home from './modules/home/router/routes';
import userProfile from './modules/user-profile/routes/routes';
import { fertilizerRoutes } from '@maximap-base/lib/modules/fertilizer';
import { mapRoutes } from '@maximap-base/lib/modules/map';
import { parcelRoutes } from '@maximap-base/lib/modules/parcel';
import { planRoutes } from '@maximap-base/lib/modules/plan';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '',
        component: () => import('./components/layouts/HomeLayout.vue'),
        children: [...home, ...login.routes]
      },
      {
        path: '',
        component: () => import('./components/layouts/MainLayout.vue'),
        children: [...userProfile, ...mapRoutes, ...parcelRoutes, ...planRoutes, ...fertilizerRoutes]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('./modules/core/pages/ErrorPage.vue')
  }
];

export default routes;
