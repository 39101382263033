export default {
  menu: {
    title: {
      tableDatabase: 'Alapadat kezelés',
      map: 'MAP',
      fertilizerDatabase: 'Input anyagok',
      pesticidesDatabase: 'Permetszer-adatbázis',
      applicationPlans: 'Kijuttatási tervek',
      substanceDatabases: 'Anyagok',
      monitoring: 'Monitoring',
      settings: 'Beállítások',
      admin: 'Admin felület',
      home: 'Főoldal',
      advices: 'Szaktanácsadások'
    },
    description: {
      substanceDatabases: 'Kijuttatható anyagok adatbázisai',
      tableDatabase: '',
      map: 'Adatok térképi megjelenítése, összehasonlítása',
      fertilizerDatabase: 'Tápanyagok felvitele, szerkesztése',
      pesticidesDatabase: 'Permetszerel felvitele, szerkesztése',
      applicationPlans: 'Tervek készítése, kezelése',
      monitoring: 'Időjárási és műholdi adatok elemzése',
      settings: 'Személyes adatok, jelszó módosítása'
    }
  },
  parcel: {
    offerTitle: {
      hectare: 'ha'
    },
    tab: {
      offers: 'Gazdaságok',
      groups: 'Táblacsoportok'
    },
    btn: {
      newGroup: 'Új csoport',
      uploadFiles: 'Fáljok feltöltése'
    },
    title: 'Adatbázis',
    toolbar: {
      title: 'Összes terület:',
      usedArea: '~{used}ha / {available}ha felhasználva',
      areaInfo: 'Az előfizetésbe foglalt terület korlát. A feltöltött határvonal által meghatározott összevont terület alapján kerül mérésre.'
    },
    layer: {
      year: 'Év',
      name: 'Név'
    },
    dialog: {
      newParcelGroup: {
        title: 'Új csoport létrehozása',
        offers: {
          title: 'Gazdaságok'
        },
        groups: {
          title: 'Csoportok'
        },
        parcels: {
          title: 'Táblák',
          selectAll: 'Összes kiválasztása'
        },
        selectedParcels: {
          title: 'Kiválasztott táblák',
          deleteAll: 'Összes kiválasztott törlése',
          parcel: 'tábla',
          hectare: 'ha'
        },
        createGroup: 'csoport létrehozása',
        parcel: 'tábla',
        hectare: 'ha',
        groupName: 'Csoport neve',
        filter: 'Szűrés',
        groupDetails: 'Csoport adatai',
        parcelName: 'Tábla neve',
        currentCrop: 'Aktuális növény',
        createSuccess: 'Sikeres létrehozás',
        createError: 'Sikertelen létrehozás',
        notUniqueGroupName: 'Ez a csoportnév már foglalt!',
        area: 'Terület',
        farmName: 'Gazdaság'
      },
      deleteLayer: {
        title: 'Tábla adat törlése',
        text: 'Biztos, hogy törli a(z) "<strong>{name}</strong> ({year})" megnevezésű adatot?'
      },
      editLayer: {
        title: 'Tábla adat módosítása'
      },
      editParcelGroup: {
        title: 'Csoport módosítása',
        editSuccess: 'Sikeres módosítás',
        editError: 'Sikertelen módosítás',
        editGroup: 'Csoport módosítása'
      },
      removeParcelGroup: {
        title: 'Tábla törlése',
        text: 'Biztos szeretné törölni a táblát?'
      }
    },
    validation: {
      missingParcelsFromGroup: 'Kiválasztott tábla nélkül nem hozható létre a csoport!'
    },
    notifications: {
      editLayer: {
        success: 'Adatok módosítása sikeres megtörtént!',
        error: 'Hiba az adatok módosítása során!'
      },
      removeLayer: {
        success: 'Réteg sikeresen törölve!',
        error: 'Hiba a réteg törlése során!'
      }
    },
    uploadInformation: 'Az egyes táblákhoz tartozó geoadatokat a szolgáltató tölti fel az ügyfeleknek. Kérjük keresse ügyfélszolgálatunkat!',
    offerList: {
      header: {
        name: 'Gazdaság neve',
        areaLimit: 'Maximum bevonható terület',
        totalArea: 'Felhasznált terület',
        parcels: 'Táblák'
      },
      parcelsModal: {
        title: 'Gazdaság táblái'
      },
      allParcels: 'Összes tábla'
    },
    hectare: 'Hektár'
  },
  fertilizer: {
    label: {
      create: 'Műtrágya létrehozása',
      name: 'Név',
      nitrogen: 'Nitrogén (N)',
      phosphor: 'Foszfor (P²O⁵)',
      potassium: 'Kálium (K²O)',
      state: 'Halmazállapot',
      solid: 'Szilárd',
      liquid: 'Folyékony',
      complex: 'Komplex',
      mono: 'Mono',
      own: 'Saját',
      maximap: 'MaxiMap',
      composits: 'Összetevők',
      composition: 'Összetétel',
      creator: 'Létrehozó'
    },
    state: {
      solid: 'Szilárd',
      liquid: 'Folyékony'
    },
    btn: {
      addNew: 'Új műtrágya'
    },
    dialog: {
      delete: {
        title: 'Törlés jóváhagyása',
        text: 'Biztosan törli a műtrágyát?'
      }
    },
    validator: {
      composition: {
        invalidMessage: 'Az összetevők százalákos arányainak összege 1 és 100 közé kell hogy essen.'
      },
      name: {
        invalidMessage: 'A megadott név foglalt!'
      }
    }
  },
  userProfile: {
    area: {
      limit: 'Limit',
      uploaded: 'Feltöltve',
      unused: 'Kihasználatlan'
    },
    confirm: {
      passwordChange: {
        title: 'Jelszó megváltoztatása',
        text: 'Biztosan megváltoztatja a jelszavát? A Vantage rendszerben is az új jelszóval fog tudni belépni a változtatás után!'
      }
    }
  },
  geoLayerUpload: {
    validation: {
      missingAttributeGiven: 'Legalább 1 attribútum megadása kötelező!'
    },
    infoModal: {
      title: 'Többszörös szerkesztés',
      text: 'Többszörös szerkesztés esetén csak azok a fájlból jövő attribútumok szerkeszthetőek, melyek minden fájlban benne vannak.'
    },
    dialog: {
      finalize: {
        title: 'Réteg véglegesítése',
        multipleTitle: 'Rétegek véglegesítése',
        text: 'Biztos, hogy véglegesíti a(z) "<strong>{name}</strong> ({year})" megnevezésű réteget?',
        multipleText: 'Biztos, hogy véglegesíti a(z) "{layers}" megnevezésű rétegeket?'
      },
      delete: {
        title: 'Réteg törlése',
        multipleTitle: 'Rétegek törlése',
        text: 'Biztos, hogy törli a(z) "<strong>{name}</strong> ({year})" megnevezésű réteget?',
        multipleText: 'Biztos, hogy törli a(z) "{layers}" megnevezésű rétegeket?'
      },
      info: {
        title: 'Információk a réteg feltöltésről'
      }
    },
    title: 'Beolvasott fájlok',
    layer: 'réteg',
    match: 'Egyezés:',
    attributeDifferenceModal: {
      title: 'Attribútumok'
    },
    nonMatchingWarning: {
      differentAttributes: 'A fájlok különböző attribútumokat taralmaznak! ',
      matchingAttributes: 'Közös attribútumok száma: {count}'
    },
    editStatusText: {
      editable: 'Hiányzó adatok',
      finalizable: 'Véglegesíthető',
      unmatched: 'Nincs egyezés'
    },
    processStatus: {
      editable: 'Hiányzó adatok!',
      unmatched: 'Nincs egyezés!'
    },
    status: {
      unmatched: 'Nincs egyezés',
      editable: 'Hiányzó adatok',
      finalizable: 'Véglegesíthető'
    },
    layerGroupKind: {
      zoning: 'Zónázás',
      selfZone: 'Saját zónaelhatárolás  ',
      soilSample: 'Talajminta',
      zoneStrength: 'Zónaerő',
      yield: 'Hozam',
      cellYield: 'Cellahozam',
      fertilizing: 'Műtrágyázás'
    },
    label: {
      name: 'Réteg neve',
      year: 'Év',
      layerGroupKind: 'Réteg típusa'
    },
    attributeTableModal: {
      title: 'Attribútum tábla',
      addAttribute: 'Attribútum hozzáadása'
    },
    workYieldAttribute: {
      mass: 'Tömeg',
      speed: 'Sebesség',
      seedWetness: 'Szemnedvesség',
      height: 'Magasság'
    },
    workCellYieldAttribute: {
      cellYield: 'Cellahozam'
    },
    workClaasCellYieldAttribute: {
      clasCellYield: 'Cellahozam'
    },
    workFertilizationAttribute: {
      deliveredDose: 'Kijuttatott dózis',
      planndedDose: 'Tervezett dózis',
      speed: 'Sebesség',
      height: 'Magasság'
    },
    workSprayingAttribute: {
      deliveredDose: 'Kijuttatott dózis',
      plannedDose: 'Tervezett dózis',
      speed: 'Sebesség',
      height: 'Magasság'
    },
    workSeedingAttribute: {
      deliveredStemNumber: 'Kijuttatott tőszám',
      planndedStemNumber: 'Tervezett tőszám',
      speed: 'Sebesség',
      height: 'Magasság'
    },
    zoneStrengthAttribute: {
      zoneStrength: 'Zónaerő'
    },
    zoningAttribute: {
      zsszt: 'ZSSZT'
    },
    soilSampleAttribute: {
      pH_KCL: 'pH-KCL',
      boundness: 'Kötöttség',
      allSalt: 'Összes só',
      CaCO3: 'CaCO3',
      humus: 'Humusz',
      P2O5: 'P2O5',
      K2O: 'K2O',
      NOX_N: 'NOX-N',
      Na: 'Na',
      SO42_S: 'SO42-S',
      Mn: 'Mn',
      Zn: 'Zn',
      Cu: 'Cu',
      Mg: 'Mg'
    },
    expertAdviceAttribute: {
      N: 'N',
      P: 'P',
      K: 'K',
      Mg: 'Mg',
      Zn: 'Zn',
      Cu: 'Cu',
      Mn: 'Mn'
    },
    classification: {
      work: 'Munka',
      zone: 'Zóna',
      plan: 'Terv'
    },
    notification: {
      editSuccess: 'Sikeres módosítás',
      editError: 'Sikertelen módosítás',
      deleteSuccess: 'Sikeres törlés',
      deleteError: 'Sikertelen törlés',
      finalizeSuccess: 'Sikeres véglegesítés',
      finalizeError: 'Sikertelen véglegesítés',
      uploadSuccess: 'Sikeres feltöltés',
      uploadError: 'Sikertelen feltöltés'
    }
  },
  button: {
    save: 'Mentés',
    edit: 'Szerkesztés',
    delete: 'Törlés',
    cancel: 'Mégse',
    finalize: 'Véglegesítés',
    upload: 'Feltöltés',
    openAttributeTable: 'Attribútum tábla megnyitása',
    details: 'Részletek',
    next: 'Tovább',
    back: 'Vissza',
    logout: 'Kijelentkezés',
    open: 'Megnyitás',
    revert: 'Visszavonás',
    openDetails: 'Megtekintés'
  },
  filter: {
    search: 'Keresés',
    status: 'Státusz',
    type: 'Típus'
  },
  notification: {
    removeSuccess: 'Sikeres törlés',
    removeError: 'Sikertelen törlés'
  },
  plan: {
    label: {
      plan: 'Kijuttatási terv',
      plans: 'Kijuttatási tervek',
      newPlan: 'Új terv készítése',
      uploadFiles: 'Fájl feltöltés',
      downloadPlans: 'Tervek letöltése',
      noPlanSelected: 'Nincs terv kiválasztva',
      planType: 'Típus',
      dosePlan: 'Dozírozás',
      name: 'Név',
      parcelNames: 'Táblák',
      parcel: 'Tábla',
      createdAt: 'Létrehozva',
      done: 'Tervek',
      year: 'Év',
      noData: 'Nincs megjeleníthető adat',
      sumApplication: 'Tervezett össz. kijuttatás',
      realSumApplication: 'Tényleges össz. kijuttatás',
      sumView: 'Összesítve',
      zoneView: 'Zónánként',
      status: 'Státusz',
      attachedWork: 'Csatolt munka',
      unknown: 'Ismeretlen',
      zone: 'Zóna',
      area: 'Terület',
      fileType: 'Fájltípus',
      layers: 'Rétegek',
      parcels: 'Táblák',
      parcelUnion: 'Táblák egyesítése',
      parcelUnionHint: 'Csak egy fájlt generál, ami az összes táblát tartalmazza',
      parcelUnionName: 'Egyesített név',
      parcelUnionNameHint: 'Alapértelmezett név: "Táblák"',
      percentage: 'Százalékos bontás',
      percentageValue: 'Százalék',
      generateNewFile: 'Új fájlok generálása'
    },
    planType: {
      fertilizerPlan: 'Műtrágya',
      pesticidePlan: 'Növényvédőszer',
      seedPlan: 'Vetőmag'
    },
    dosePlan: {
      zoneStrength: 'Zónaerők',
      selfZone: 'Saját zónalehatárolás',
      homogeneous: 'Homogén',
      advice: 'Szaktanácsadás'
    },
    fileType: {
      trimbleGfxTmx: 'Trimble GFX TMX',
      trimbleCfx: 'Trimble CFX',
      topcon: 'Topcon',
      shpFile: 'SHP'
    },
    messages: {
      planLoadError: 'Hiba történt a kijuttatási terv betöltése során',
      planDownloadError: 'Hiba történt a fájl letöltése során',
      planFileGenerationError: 'Hiba történt a fájl előállítása során',
      planFileGenerationSuccess: 'A fájl elkészült'
    },
    wizard: {
      step: {
        planType: 'Kijuttatandó anyag',
        dosePlan: 'Számolási módszer',
        baseSettings: 'Alapbeállítások',
        application: 'Kijuttatás',
        overview: 'Áttekintés'
      },
      label: {
        openParcels: 'Táblák megnyitása',
        saveGroup: 'Csoport mentése',
        parcelSummary: '<strong>Összesen:</strong> {parcelCount} tábla, {size} ha',
        appliedNutrients: 'Kijuttott tápanyagok (max. {maxNutrients})',
        appliedNutrient: 'Kijuttatandó anyag',
        problematicTables: '{problematicParcelsCount} tábla miatt nem folytatható a tervezés!',
        addNutrient: 'Tápanyag hozzáadása',
        zoning: 'Zónázás',
        advice: 'Szaktanács',
        zones: 'Zónák',
        zone: 'Zóna',
        area: 'Terület',
        nutrient: 'Tápanyag',
        nutrients: 'Kijuttatandó anyagok',
        amount: 'Mennyiség',
        nitrogen: 'Nitrogén',
        phosphorus: 'Foszfor',
        potassium: 'Kálium',
        planName: 'Terv neve',
        savePlan: 'Terv mentése',
        optimization: 'Optimalizálás',
        intensity: 'Intenzitás',
        zoneStrength: 'Zónaerők',
        zoneStr: 'Zónaerő',
        groupName: 'Csoport neve',
        seed: 'Vetőmag',
        applicationPerHa: 'Kijuttatás /ha',
        applicationAll: 'Össz. kijuttatás',
        sum: 'Összesen',
        minimum: 'Minimum',
        maximum: 'Maximum'
      },
      messages: {
        loadOffersError: 'Hiba történt a táblák betöltése során',
        openParcelsError: 'Hiba történt a kiválasztott táblák megnyitása során',
        loadLayerGroupError: 'Hiba történt a kiválasztott zónák betöltése során',
        savePlanError: 'Hiba történt a kijuttatási terv mentése során',
        savePlanSuccess: 'Sikeres mentés'
      },
      advice: {
        optimizationInfo: {
          title: 'Optimalizálási információk'
        },
        optimization: {
          npk: 'Teljes (NPK)',
          np: 'Foszfor / Nitrogén (NP)',
          nk: 'Kálium / Nitrogén (NK)',
          pk: 'Foszfor / Kálium (PK)'
        },
        intenzityInfo: {
          title: 'Intenzitás információk'
        },
        intensity: {
          minimum: 'Minimum',
          environment: 'Környezetkímélő',
          scale: 'Mérlegszemléletű',
          maximum: 'Maximum'
        }
      },
      doseTypeTooltip: {
        homogeneous: 'A tábla minden részére azonos, a felhasználó által megadott dózis/tőszám kerül.',
        zoneStrength:
          '<p>A megadott kiindulási dózis/tőszám a Trimble Power Zone, vagy Axiál mAXI-Hozam által megadott zónák és zónaerők alapján kerül automatikusan differenciálásra.</p><p>Előfeltétel: ezek megléte.</p>',
        selfZone: 'A megadott kiindulási dózis/tőszám zónánként kézzel állítható súlyozás alapján kerül differenciálásra.',
        advice:
          '<p>A dózis/tőszám a Trimble Power Zone, vagy Axiál mAXI-Hozam által megadott zónák és zónaerők, valamint az azokra vonatkozó talaj labor adatok alapján, a ProPlanta Trágyázási Szaktanácsadó rendszer által kerül meghatározásra.</p><p>Előfeltétel: ezek megléte.</p>'
      }
    },
    modal: {
      saveGroupTitle: 'Csoport mentése',
      openParcelsTitle: 'Táblák megnyitása',
      downloadFileTitle: 'Terv letöltése',
      downloadFileSuccess: 'A fájl elkészült, kérjük kattintson a "Letöltés" gombra.',
      downloadFilePolling: 'A fájl készítése folyamatban...',
      deleteTitle: 'Terv törlése',
      deleteText: 'Biztos, hogy törlöd a(z) <strong>"{planName}"</strong> elnevezésű kijuttatási tervet?'
    }
  },
  expertAdvice: {
    label: {
      parcelName: 'Tábla',
      date: 'Dátum',
      area: 'Terület',
      provider: 'Szolgáltató',
      crop: 'Növény',
      selectOne: 'Válasszon szaktanácsadást!'
    }
  },
  errorPage: {
    title: '404',
    description: 'A keresett oldal nem található',
    homeBtn: 'Vissza a főoldalra'
  },
  map: {
    layerHintGroupMode: {
      isolated: 'Izolált',
      merged: 'Összesített'
    },
    devidedMaps: 'Osztott',
    advancedSettings: 'Haladó beállítások',
    classification: 'Osztályozás',
    layout: 'Elrendezés',
    mapSettings: 'Térkép beállítások',
    isolated: 'Izolált',
    isolatedDescription: 'Minden rétegre külön osztályozás a saját min-max értékei alapján',
    summarized: 'Összesített',
    summarizedDescription: 'Egy átfogó osztályzás az összes azonos típusú rétegre',
    layers: 'Gazdálkodási adatok',
    map: 'Térkép',
    automaticRotation: 'Automatikus rotáció',
    parcelsData: 'Tábla adatok',
    plans: 'Kijuttatási tervek',
    jumpToScale: 'Terjedelemre ugrás',
    subLayers: 'Alrétegek',
    opacity: 'Átlátszóság',
    legend: 'Jelmagyarázat',
    removeLayer: 'Réteg törlése',
    removeAllLayers: 'Összes réteg törlése',
    addedLayers: 'Megjelenítés',
    showTables: 'Táblák megjelenítése',
    addLayer: 'Réteg hozzáadása',
    layer: 'Réteg',
    parcelBorder: 'Táblahatár'
  },
  pesticide: {
    label: {
      name: 'Név',
      unitType: 'Mértékegység',
      creator: 'Létrehozó',
      own: 'Saját',
      maximap: 'mAXI-MAP'
    },
    filter: {
      searchText: 'Keresés'
    },
    btn: {
      addNew: 'Új permetszer'
    },
    details: {
      title: 'Új permetszer létrehozása'
    },
    dialog: {
      delete: {
        title: 'Permetszer törlése',
        text: 'Biztosan törli a permetszert?'
      }
    },
    notification: {
      deleteSuccess: 'Permetszer sikeresen törölve!',
      deleteError: 'Permetszer törlése során hiba lépett fel!',
      saveSuccess: 'Permetszer sikeresen mentve!',
      saveError: 'Permetszer mentése során hiba lépett fel!'
    }
  },
  substanceDatabases: {
    fertilizersTab: 'Műtrágya-adatbázis',
    pesticidesTab: 'Permetszer-adatbázis'
  }
};
