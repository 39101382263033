export interface DefaultFormElementAttributes {
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | string;
  bgColor?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | string;
  variant?: 'solo' | 'filled' | 'outlined' | 'plain' | 'underlined';
  density?: 'default' | 'comfortable' | 'compact';
  elevation?: number;
}

export class FormDefaultAttributes {
  private static _formAttributes: DefaultFormElementAttributes = {
    variant: 'outlined',
    density: 'compact',
    color: 'primary'
  };

  public static setFormDefaultAttributes(attributes: DefaultFormElementAttributes) {
    FormDefaultAttributes._formAttributes = attributes;
  }

  public static getFormDefaultAttributes(): DefaultFormElementAttributes {
    return FormDefaultAttributes._formAttributes;
  }
}
