import type { ThemeDefinition } from 'vuetify';

const light: ThemeDefinition = {
  dark: true,
  colors: {
    primary: '#ab0534',
    secondary: '#00448c',
    success: '#4caf50',
    info: '#3498ff',
    'info-lighten-1': '#f0f9ff',
    warning: '#ffb300',
    error: '#df2026',
    blue: '#3498ff'
  }
};

const dark: ThemeDefinition = {
  dark: true,
  colors: {}
};

export default { light, dark };
