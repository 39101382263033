import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'fertilizers',
    path: '/fertilizers',
    component: () => import('../pages/FertilizerListPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_PLAN
    }
  }
];

export default routes;
